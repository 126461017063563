<template>
    <v-radio-group row v-model="model[config.key]" @change="config.change">
        <v-radio v-for="item in config.items" :key="item.value"
                 :label="item.label" :dense="config.dense"
                 :value="item.value"
        ></v-radio>
    </v-radio-group>
</template>

<script>
export default {
    name: "FormRadioGroup",
    props: ['config', 'model', 'errors'],
}
</script>

<style scoped>

</style>
